<template>
  <div class="content content_in">
    <div class="title-row row justify-content-between">
      <div class="col-12 col-auto">
        <h1>{{ ticket.name }}</h1>
      </div>
      <div class="col-12 col-md-auto">
        <button @click="closeTicket" class="btn _has-icon _mob100">
          <svg
            v-svg
            symbol="lock-open"
            size="0 2 16 18"
            role="presentation"
          />{{
            $t(`support.${ticket.status == 1 ? "open_ticket" : "close_ticket"}`)
          }}
        </button>
      </div>
    </div>
    <div class="sep _h20"></div>
    <div class="chat">
      <div class="chat__list">
        <div ref="scroll" class="chat__scroll">
          <div
            v-for="(message, index) in messages"
            :key="index"
            class="chat__message"
            :class="{ '_support-answer': message.user_id === user.id }"
          >
            <div class="message">
              <div class="message__author font-weight-bold">
                {{
                  message.user_id === user.id
                    ? user.name
                    : $t("support_partners")
                }}
              </div>
              <div class="message__date">{{ message.date }}</div>
              <div class="message__text">{{ message.message }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="chat__form">
        <div class="row">
          <div class="col-12 col-xl-7">
            <div class="form-item pb-2">
              <div class="form-item__field">
                <textarea
                  v-model.trim="ticketText"
                  name="text"
                  :placeholder="$t('enter_message')"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-5">
            <button
              :disabled="!ticketText.length || ticket.status === 1"
              @click="sendMessage"
              type="submit"
              class="btn _mob100"
            >
              {{ $t("support.modal.submit") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <alert v-if="$alert.title" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SupportService from "@/api/supportService";
import "@/assets/css/_page-support.scss";
export default {
  name: "SupportChat",
  data() {
    return {
      ticket: {},
      ticketText: "",
      messages: [],
    };
  },
  created() {
    this.getTicket();
  },
  watch: {
    messages: function () {
      if (document.getElementsByClassName("message").length) {
        setTimeout(() => {
          this.$refs.scroll.scrollTo({
            top: this.$refs.scroll.scrollHeight,
            behavior: "smooth",
          });
        }, 100);
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
  },
  methods: {
    getTicket() {
      SupportService.getTicket(this.$route.params).then((res) => {
        if (res && res.status === 200) {
          this.ticket = res.data;
          this.getMessagesInTicket(this.ticket.id);
        }
      });
    },
    getMessagesInTicket() {
      SupportService.getMessagesInTicket(this.$route.params).then((res) => {
        if (res && res.status === 200) {
          this.messages = res.data;
        }
      });
    },
    sendMessage() {
      let formData = new FormData();
      formData.append("id", this.ticket.id);
      formData.append("message", this.ticketText);
      let params = {
        formData: formData,
        id: this.ticket.id,
      };
      if (!this.ticketText || this.ticket.status === 1) {
        this.$alert({
          title: this.$t("sent_error"),
          text: this.$t("fields_invalid"),
        });
      } else {
        SupportService.createMessage(params).then((res) => {
          if (res) {
            if (res.status === 200) {
              this.getMessagesInTicket();
              this.ticketText = "";
              this.$alert({
                title: this.$t("sent_success"),
                text: this.$t("sent_success_message"),
                type: "success",
              });
            } else {
              if (res.status) {
                this.$alert({
                  title: this.$t("sent_error"),
                  text: res.message,
                });
              }
            }
          }
        });
        this.ticketText = "";
      }
    },
    closeTicket() {
      const payload = {
        id: this.$route.params.id,
        status: !this.ticket.status,
      };
      SupportService.setTicketStatus(payload).then((res) => {
        if (res && res.status === 200) {
          if (!this.ticket.status == 1) {
            this.$router.push("/support");
          }
          this.ticket.status = !this.ticket.status;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.chat .chat__message .message {
  margin-bottom: 15px;
}

.btn {
  svg {
    fill: #fff;
  }
}
</style>
